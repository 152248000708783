import React from "react";
import { Box, Button, Card, CardMedia, Typography } from "@mui/material";

const ImageUpload = ({
  label,
  selectedImage,
  onImageChange,
  imagePreview,
  defaultImage = "",
  imageLink,
  imageStyle = {},
}) => {
  const ImageSrc = imagePreview || `${imageLink}${selectedImage}`;

  return (
    <Card
      sx={{
        maxWidth: 300,
        padding: 2,
        textAlign: "center",
        margin: "0 auto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{ marginBottom: 2, fontWeight: "bold" }}
      >
        {label} Preview
      </Typography>
      <CardMedia
        component="img"
        image={ImageSrc}
        alt={`${label}`}
        sx={{
          height: 150,
          width: 150,
          borderRadius: "50%",
          margin: "0 auto",
          objectFit: "cover",
          ...imageStyle,
        }}
        onError={(e) => {
          e.target.src = defaultImage;
        }}
      />
      <Box sx={{ marginTop: 2 }}>
        <input
          style={{ display: "none" }}
          id={`upload-${label}`}
          type="file"
          onChange={onImageChange}
        />
        <label htmlFor={`upload-${label}`}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{ textTransform: "none" }}
          >
            Upload {label}
          </Button>
        </label>
      </Box>
    </Card>
  );
};

export default ImageUpload;








// import React from "react";
// import { Box, Fab } from "@mui/material";

// const ImageUpload = ({ label, selectedImage, onImageChange, imagePreview, defaultImage = "", imageLink, imageStyle, }) => {

//   const ImageSrc = imagePreview || `${imageLink}${selectedImage}`;

//   return (
//     <div style={{ textAlign: "center" }}>
//       <img
//         src={ImageSrc}
//         alt={`${label} Preview`}
//         style={imageStyle}
//         onError={(e) => {
//           e.target.src = defaultImage;
//         }}
//       />
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "10px",
//         }}
//       >
//         <label htmlFor={`upload-${label}`}>
//           <input
//             style={{ display: "none" }}
//             id={`upload-${label}`}
//             type="file"
//             onChange={onImageChange}
//           />
//           <Fab
//             color="primary"
//             size="small"
//             component="span"
//             aria-label="add"
//             variant="extended"
//           >
//             Upload {label}
//           </Fab>
//         </label>
//       </Box>
//     </div>
//   );
// };

// export default ImageUpload;
