import { selectedPageSelector, setSelectedPage } from "app/rkt_query/SelectedStuffReducer";
import { useGetPageQuery } from "app/rkt_query/storeApis";
import React from "react";
import { useSelector } from "react-redux";
import DZGenericView from "@jumbo/dz-components/dzgeneric/DZGenericView";

const ViewPage = () => {
  const selectedPage = useSelector(selectedPageSelector);
  const { data: res_get } = useGetPageQuery(setSelectedPage?.id);
  const projectArray = [
    { label: "User Name", data: res_get?.data?.project?.user?.name },
    { label: "User Email", data: res_get?.data?.project?.user?.email },
    { label: "Service", data: res_get?.data?.project?.name },
  ];

  return (
    <div>
      {selectedPage?.id ? (
        <DZGenericView obj={projectArray} closeBtn={true} />
      ) : (
        <div>Bad Code</div>
      )}
    </div>
  );
};

export default ViewPage;
