import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useState } from 'react'
import { useGetAllClickupProjectsQuery, BLUE_PRINTS, useGetAllClickupTimesQuery, useGetAllClickupMembersQuery, } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedClickupTime } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ViewClickupTimeSheet from './ViewClickupTimeSheet';

import DZDateRangePicker from '@jumbo/dz-components/date-range/DZDateRangePicker';
import DZMultiSelector from '@jumbo/dz-components/dropdowns/DZMultiSelector';

const ViewFilters = ({setSelectedProject, setSelectedMember,  setSelectedStartDate, setSelectedEndDate}) => {
  const { data: res_allProjects, } = useGetAllClickupProjectsQuery();
  const allProjects = res_allProjects?.data?.folders;
  
  const { data: res_allMembers, } = useGetAllClickupMembersQuery();
  const allMembers = res_allMembers?.data;

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "20px", gap: "10px", }}> 
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "20px", gap: "10px", marginTop: "9px", }}> 
        
        <DZMultiSelector id="checkboxes-projects" label="Projects" data={allProjects || []}
          getOptionLabel={(data) => data?.name}
          onChange={(event, newValue) =>  setSelectedProject(newValue?.map((project) => project.id)) }
        />

        <DZMultiSelector id="checkboxes-users" label="Users" data={allMembers || []}
          getOptionLabel={(data) => data?.user?.username}
          onChange={(event, newValue) =>  setSelectedMember(newValue.map((project) => project?.user?.id)) }
        />

      </div>
      <DZDateRangePicker setStartDate={setSelectedStartDate} setEndDate={setSelectedEndDate}/>
    </div>
  );
};


const ClickupTimeSheet = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState([]);

  const { data: res_get_all, isLoading, error } = useGetAllClickupTimesQuery({ 
    user_id:selectedMember?.join(', '),  start_date:selectedStartDate, end_date:selectedEndDate, project_id:selectedProject?.join(', ') 
  });
  const tableData = res_get_all?.data?.data;
  const [showMode, setShowMode] = useState(false);
  console.log({ 
    user_id:selectedMember?.join(', '),  start_date:selectedStartDate, end_date:selectedEndDate, project_id:selectedProject?.join(', ') 
  });
  const bluePrint = BLUE_PRINTS.clickupTimes;
  // bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedClickupTime(row)); }
  
  return (
    <>
      <span>{showMode}</span>
      <JumboDemoCard
        title={"Clickup Time Sheet"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable ViewOnTable={
              <ViewFilters setSelectedProject={setSelectedProject} setSelectedMember={setSelectedMember} 
                setSelectedStartDate={setSelectedStartDate} setSelectedEndDate={setSelectedEndDate}
              />
            } 
            hideActionColumn HeaderAddButton={false} showLanguageSwitcher={false} 
            data={tableData} bluePrint={bluePrint} ViewModel={() => <ViewClickupTimeSheet />} 
          />
        </div>
      </JumboDemoCard>
    </>
  )
}


export default ClickupTimeSheet