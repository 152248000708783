import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { BLUE_PRINTS, useDeleteCaseStudyMutation, useGetAllCaseStudiesQuery } from 'app/rkt_query/storeApis';
import { useDispatch, } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedCaseStudy } from 'app/rkt_query/SelectedStuffReducer';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateCaseStudy from './AddUpdateCaseStudy';
import ViewCaseStudy from './ViewCaseStudy';


const CaseStudyList = () => {
  const dispatch = useDispatch();
  const {hideDialog } = useJumboDialog();
  const [deleteCaseStudy, responseDelete] = useDeleteCaseStudyMutation();
  
  const { data: caseStudyData, isLoading: isLoadingCaseStudies, error: errorCaseStudies } = useGetAllCaseStudiesQuery();
  console.log("Case Study:", caseStudyData);
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.caseStudy
  console.log("blueprint: case study", bluePrint)
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCaseStudy(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCaseStudy(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCaseStudy(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedCaseStudy(row)); }
  bluePrint.handleDelete = (id) => { deleteCaseStudy(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Case Studies"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Case Studies"  showLanguageSwitcher={true}  data={caseStudyData?.data?.caseStudies} bluePrint={bluePrint} ViewModel={() => <ViewCaseStudy />} ModalContent={() => <AddUpdateCaseStudy onClose={hideDialog} />} />
        </div>
      </JumboDemoCard>
    </>
  )
}


export default CaseStudyList
