import React, { useState } from "react";

import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

import RoomServiceIcon from "@mui/icons-material/RoomService";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LayersIcon from "@mui/icons-material/Layers";

const menus = [
  {
    label: "modules",
    type: "section",
    children: [
      {
        uri: "/service",
        label: "Services",
        type: "nav-item",
        visible: true,
        icon: <RoomServiceIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/case-study",
        label: "Case Studies",
        type: "nav-item",
        visible: true,
        icon: <AccountTreeIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/page",
        label: "Pages",
        type: "nav-item",
        visible: true,
        icon: <LayersIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/company",
        label: "Company",
        type: "nav-item",
        visible: true,
        icon: <EmojiTransportationIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/contact",
        label: "Contacts",
        type: "nav-item",
        visible: true,
        icon: <ContactMailIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "authPages",
    type: "section",
    children: [
      {
        uri: "/admins",
        label: "Admin",
        type: "nav-item",
        visible: true,
        icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
        is_super: true,
      },
      // {
      //     uri:"/users",
      //     label: 'User',
      //     type: "nav-item",
      //     visible: true,
      //     icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
      // },
    ],
  },
];

export default menus;
