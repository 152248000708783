import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useState } from 'react'
import { BLUE_PRINTS, useGetAllPagesQuery, useDeletePageMutation } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedPage } from 'app/rkt_query/SelectedStuffReducer';
import ViewPage from './ViewPage';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';

import AddUpdatePage from './AddUpdatePage';

const PageList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();

  const [deletePage, responseDelete] = useDeletePageMutation();

  const { data: res_get_all, isLoading: isLoadingPages, error: errorPages } = useGetAllPagesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.page
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedPage(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedPage(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedPage(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedPage(row)); }
  bluePrint.handleDelete = (id) => { deletePage(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Pages"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Pages"  showLanguageSwitcher={true}  data={res_get_all?.data} bluePrint={bluePrint} ViewModel={() => <ViewPage />} ModalContent={() => <AddUpdatePage onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default PageList