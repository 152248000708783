import { selectedCaseStudySelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetServiceQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewCaseStudy = () => {

    const selectedCaseStudy = useSelector(selectedCaseStudySelector);
    const { data: res_get } = useGetServiceQuery(selectedCaseStudy?.id);
    const projectArray =
        [
            { label: 'User Name', data: res_get?.data?.project?.user?.name },
            { label: 'User Email', data: res_get?.data?.project?.user?.email },
            { label: 'Service', data: res_get?.data?.project?.name },
        ]

    return (
        <div>

            {
                  selectedCaseStudy?.id ?
                        <DZGenericView obj={projectArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewCaseStudy

