import React from "react";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DZMultiSelector = ({
  id="multi-selector",
  label,
  data,
  getOptionLabel,
  onChange,
  style,
}) => {
  return (
    <Autocomplete
      multiple
      id={id}
      disableCloseOnSelect
      style={{ width: 300, ...style }}
      options={data || []}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderOption={(props, option, { selected }) => {
        const { id, ...optionProps } = props;
        return (
          <li key={id} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {getOptionLabel(option)}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} placeholder="" />}
    />
  );
};

export default DZMultiSelector;

{/* <DZMultiSelector id="checkboxes-projects" label="Projects" data={allProjects || []}
          getOptionLabel={(option) => option?.name}
          onChange={(event, newValue) =>  setSelectedProject(newValue.map((project) => project.id)) }
        /> */}