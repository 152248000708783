import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
       
        selectedAdmin: null,
        selectedCompany: null,
       
        selectedUserLogin: null,
       
        selectedUser: null,
        selectedService: null, 
        selectedCaseStudy: null, 
        selectedPage: null, //
        selectedContact: null, 

        selectedClickupTime: null,
    },

    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
    
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
      
    
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
      

        setSelectedUser: (state, action) => {
           
            state.selectedUser = action.payload;
        },
        setSelectedService: (state, action) => { 
     
            state.selectedService = action.payload;
        },
        setSelectedCaseStudy: (state, action) => { 
     
            state.selectedCaseStudy = action.payload; 
        },
        setSelectedPage: (state, action) => { //
     
            state.selectedPage = action.payload; //
        },
        setSelectedContact: (state, action) => { 
     
            state.selectedContact = action.payload;
        },
        setSelectedClickupTime: (state, action) => {
            state.selectedClickupTime = action.payload;
        },
     
     
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
          
            state.selectedAdmin= null;
            state.selectedCompany= null;
           
            state.selectedUser = null;
            state.selectedService = null; 
            state.selectedCaseStudy = null; 
            state.selectedPage = null; //
            state.selectedContact = null;
            state.selectedClickupTime = null;

        },
    },
});

export const {
    setSelectedUserLogin,
   
    setSelectedAdmin,
    setSelectedCompany,

    setSelectedUser,
    setSelectedService, 
    setSelectedCaseStudy, 
    setSelectedPage, //
    setSelectedContact, 
    setSelectedClickupProject,
    setSelectedClickupMember,
    setSelectedClickupTime,
   

} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedCompanySelector = (state) => state.SelectedStuffReducer.selectedCompany;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;
export const selectedServiceSelector = (state) => state.SelectedStuffReducer.selectedService; 
export const selectedCaseStudySelector = (state) => state.SelectedStuffReducer.selectedCaseStudy; 
export const selectedPageSelector = (state) => state.SelectedStuffReducer.selectedPage; //

export const selectedContactSelector = (state) => state.SelectedStuffReducer.selectedContact; 
export const selectedClickupTimeSelector = (state) => state.SelectedStuffReducer.selectedClickupTime;

//old



export default slice.reducer;