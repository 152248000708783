import { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';


export default function DZDateRangePicker({setStartDate, setEndDate}) {
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]? dayjs(newDates[0]).format('YYYY-MM-DD HH:mm:ss') : null);
    setEndDate(newDates[1]? dayjs(newDates[1]).format('YYYY-MM-DD HH:mm:ss') : null);
    setSelectedDates(newDates);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateRangePicker']}>
        <DateRangePicker localeText={{ start: 'Start', end: 'End' }} value={selectedDates} onChange={handleDateChange} />
      </DemoContainer>
    </LocalizationProvider>
  );
}