import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  selectedServiceSelector,
  setSelectedService,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddServiceMutation,
  useGetServiceQuery,
  useUpdateServiceMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZRadioGroup from "@jumbo/dz-components/radio-button/DzRadioGroup";

const AddUpdateService = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedService = useSelector(selectedServiceSelector);

  const { data: res_get } = useGetServiceQuery(selectedService?.id);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const [updateService, responseUpdate] = useUpdateServiceMutation();
  const [addService, responseAdd] = useAddServiceMutation();
  const bluePrint = BLUE_PRINTS.service;
  const [serviceState, setServiceState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.service?.image
  );
  const addUpdateServiceData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.service?.id);
    formData.append("name", JSON.stringify(serviceState.name));
    formData.append("title", JSON.stringify(serviceState.title));
    formData.append("overview", JSON.stringify(serviceState.overview));
    formData.append("description", JSON.stringify(serviceState.description));
    formData.append(
      "meta_description",
      JSON.stringify(serviceState.meta_description)
    );
    formData.append("image", selectedFile);
    const res =
      action === "add"
        ? await addService(formData)
        : await updateService(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    res_get?.data?.service?.image &&
      setSelectedFile(res_get?.data?.service?.image);
    setServiceState(selectedService ? res_get?.data?.service : bluePrint);
  }, [res_get, selectedService, bluePrint]);
  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {serviceState ? (
        <div style={addUpdateDivStyleGlobal}>
          {imagePreview ? (
            <img
              src={imagePreview}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          ) : (
            <DZImageCircle
              image={selectedFile}
              link={Config.digitalOceanLinkServicesSmallImg}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>
          <DZTextValidation
            error={error?.name}
            label="Name"
            variant="outlined"
            value={serviceState?.name?.[selectedLanguage]}
            onChange={(e) => {
              setServiceState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />

          <DZTextValidation
            error={error?.title}
            label="Title"
            variant="outlined"
            value={serviceState?.title?.[selectedLanguage]}
            onChange={(e) => {
              setServiceState((x) => ({
                ...x,
                title: { ...x.title, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Title"
          />

          <DZTextValidation
            error={error?.description}
            label="Description"
            variant="outlined"
            value={serviceState?.description?.[selectedLanguage]}
            onChange={(e) => {
              setServiceState((x) => ({
                ...x,
                description: {
                  ...x.description,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Description"
          />

          <DZTextValidation
            error={error.overview}
            label="Overview"
            variant="outlined"
            value={serviceState?.overview?.[selectedLanguage]}
            onChange={(e) => {
              setServiceState((x) => ({
                ...x,
                overview: { ...x.overview, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Overview"
          />

          <DZTextValidation
            error={error.meta_description}
            label="Meta Description"
            variant="outlined"
            value={serviceState?.meta_description?.[selectedLanguage]}
            onChange={(e) => {
              setServiceState((x) => ({
                ...x,
                meta_description: {
                  ...x.meta_description,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Meta Description"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedService(null));
              }}
            >
              Cancel
            </Button>
            {selectedService?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateServiceData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateServiceData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedService(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateService;
