import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Box, Button, Fab } from "@mui/material";
import {
  selectedCaseStudySelector,
  setSelectedCaseStudy,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCaseStudyMutation,
  useGetCaseStudyQuery,
  useUpdateCaseStudyMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import ImageUpload from "app/DZcomponents/ImageUpload/ImageUpload";

const AddUpdateCaseStudy = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedCaseStudy = useSelector(selectedCaseStudySelector);

  const { data: res_get } = useGetCaseStudyQuery(selectedCaseStudy?.id);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const [updateCaseStudy, responseUpdate] = useUpdateCaseStudyMutation();
  const [addCaseStudy, responseAdd] = useAddCaseStudyMutation();
  const bluePrint = BLUE_PRINTS.caseStudy;
  const [caseStudyState, setCaseStudyState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  //image
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.caseStudy?.image
  );
  //logo image
  const [selectedLogo, setSelectedLogo] = useState(
    res_get?.data?.caseStudy?.logo
  );
  const [logoImagePreview, setLogoImagePreview] = useState("");

  //client image
  const [selectedClientImage, setSelectedClientImage] = useState(
    res_get?.data?.caseStudy?.client?.client_img
  );
  const [clientImagePreview, setClientImagePreview] = useState("");

  const addUpdateCaseStudyData = async (action) => {
    const formData = new FormData();

    // Assuming selectedLanguage is already defined for translation purposes
    formData.append("id", res_get?.data?.caseStudy?.id || "");

    // Translating multilingual fields
    formData.append("name", JSON.stringify(caseStudyState.name));
    formData.append("title", JSON.stringify(caseStudyState.title));

    formData.append(
      "over_view_detail",
      JSON.stringify(caseStudyState.over_view_detail)
    );
    formData.append(
      "challenges_and_solutions",
      JSON.stringify(caseStudyState.challenges_and_solutions)
    );
    formData.append(
      "results_and_client_satisfaction",
      JSON.stringify(caseStudyState.results_and_client_satisfaction)
    );
    formData.append(
      "client_detail",
      JSON.stringify(caseStudyState.client_detail)
    );
    formData.append(
      "client_title",
      JSON.stringify(caseStudyState.client_title)
    );

    // Non-multilingual fields (append directly)
    formData.append("client_name", caseStudyState.client_name || "");
    formData.append("year", caseStudyState.year || "");
    formData.append("services", caseStudyState.services || "");
    formData.append("website_url", caseStudyState.website_url || "");
    //image
    formData.append("image", selectedFile);
    // logo image
    formData.append("logo", selectedLogo);
    // client image
    formData.append("client_img", selectedClientImage);

    formData.append("tools", caseStudyState.tools || "");

    const res =
      action === "add"
        ? await addCaseStudy(formData)
        : await updateCaseStudy(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };

  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    console.log(
      "client image",
      Config.digitalOceanLinkCaseStudySmallImg.res_get?.data?.caseStudy?.client
        ?.client_img
    );
    res_get?.data?.caseStudy?.image &&
      setSelectedFile(res_get?.data?.caseStudy?.image);
    res_get?.data?.caseStudy?.logo &&
      setSelectedLogo(res_get?.data?.caseStudy?.logo);
    res_get?.data?.caseStudy?.client?.client_img &&
      setSelectedClientImage(res_get?.data?.caseStudy?.client?.client_img);
    setCaseStudyState(
      selectedCaseStudy
        ? {
            ...res_get?.data?.caseStudy,
            client_name: res_get?.data?.caseStudy?.client?.client_name,
            client_title: res_get?.data?.caseStudy?.client?.client_title,
            client_detail: res_get?.data?.caseStudy?.client?.client_detail,
          }
        : bluePrint
    );
  }, [res_get, selectedCaseStudy, bluePrint]);

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFileChange = (e, setFile, setPreview) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const fileHandle = (e, setImage, setPreview) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogo = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedLogo(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleClientImage = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedClientImage(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setClientImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {caseStudyState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* Photo Upload */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            <ImageUpload
              label="Photo"
              selectedImage={selectedFile}
              imagePreview={imagePreview}
              imageLink={Config.digitalOceanLinkCaseStudySmallImg}
              onImageChange={(e) =>
                handleFileChange(e, setSelectedFile, setImagePreview)
              }
              imageStyle={{
                height: 100,
                width: 100,
                borderRadius: 50,
              }}
            />
          </div>
          <ImageUpload
            label="Logo"
            selectedImage={selectedLogo}
            imagePreview={logoImagePreview}
            imageLink={Config.digitalOceanLinkLogoSmallImg}
            onImageChange={(e) =>
              handleFileChange(e, setSelectedLogo, setLogoImagePreview)
            }
            imageStyle={{
              height: 100,
              width: 100,
              borderRadius: 50,
            }}
          />

          <ImageUpload
            label="Client Image"
            selectedImage={selectedClientImage}
            imagePreview={clientImagePreview}
            imageLink={Config.digitalOceanLinkClientSmallImg}
            onImageChange={(e) =>
              handleFileChange(e, setSelectedClientImage, setClientImagePreview)
            }
            imageStyle={{
              height: 100,
              width: 100,
              borderRadius: 50,
            }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <ImageUpload
              label="Case Study Photo"
              selectedImage={selectedFile}
              imagePreview={imagePreview}
              imageLink={Config.digitalOceanLinkCaseStudySmallImg}
              onImageChange={(e) =>
                handleFileChange(e, setSelectedFile, setImagePreview)
              }
              imageStyle={{
                height: 100,
                width: 100,
                borderRadius: 50,
              }}
            />
            <ImageUpload
              label="Case Study Logo"
              selectedImage={selectedLogo}
              imagePreview={logoImagePreview}
              imageLink={Config.digitalOceanLinkLogoSmallImg}
              onImageChange={(e) =>
                handleFileChange(e, setSelectedLogo, setLogoImagePreview)
              }
              imageStyle={{
                height: 100,
                width: 100,
                borderRadius: 50,
              }}
            />
            <ImageUpload
              label="Client Profile Photo"
              selectedImage={selectedClientImage}
              imagePreview={clientImagePreview}
              imageLink={Config.digitalOceanLinkClientSmallImg}
              onImageChange={(e) =>
                handleFileChange(
                  e,
                  setSelectedClientImage,
                  setClientImagePreview
                )
              }
              imageStyle={{
                height: 100,
                width: 100,
                borderRadius: 50,
              }}
            />
          </div>

          <DZTextValidation
            error={error?.name}
            label="Name"
            variant="outlined"
            value={caseStudyState?.name?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />

          <DZTextValidation
            error={error?.title}
            label="Title"
            variant="outlined"
            value={caseStudyState?.title?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                title: { ...x.title, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Title"
          />

          <DZTextValidation
            error={error?.over_view_detail}
            label="Overview"
            variant="outlined"
            value={caseStudyState?.over_view_detail?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                over_view_detail: {
                  ...x.over_view_detail,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Overview"
          />

          <DZTextValidation
            error={error?.challenges_and_solutions}
            label="Challenges and Solutions"
            variant="outlined"
            value={caseStudyState?.challenges_and_solutions?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                challenges_and_solutions: {
                  ...x.challenges_and_solutions,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Challenges and Solutions"
          />

          <DZTextValidation
            error={error?.results_and_client_satisfaction}
            label="Results and Client Satisfaction"
            variant="outlined"
            value={
              caseStudyState?.results_and_client_satisfaction?.[
                selectedLanguage
              ]
            }
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                results_and_client_satisfaction: {
                  ...x.results_and_client_satisfaction,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Results and Client Satisfaction"
          />

          <DZTextValidation
            error={error?.client_name}
            label="Client Name"
            variant="outlined"
            value={caseStudyState?.client_name}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                client_name: e.target.value,
              }));
            }}
            placeholder="Client Name"
          />

          <DZTextValidation
            error={error?.client_title}
            label="Client Title"
            variant="outlined"
            value={caseStudyState?.client_title?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                client_title: {
                  ...x.client_title,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Client Title"
          />

          <DZTextValidation
            error={error?.client_detail}
            label="Client Detail"
            variant="outlined"
            value={caseStudyState?.client_detail?.[selectedLanguage]}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                client_detail: {
                  ...x.client_detail,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
            placeholder="Client Detail"
          />

          <DZTextValidation
            error={error?.year}
            label="Year"
            variant="outlined"
            value={caseStudyState?.year}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                year: e.target.value,
              }));
            }}
            placeholder="Year"
          />

          <DZTextValidation
            error={error?.services}
            label="Services"
            variant="outlined"
            value={caseStudyState?.services}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                services: e.target.value,
              }));
            }}
            placeholder="Services"
          />

          <DZTextValidation
            error={error?.website_url}
            label="Website URL"
            variant="outlined"
            value={caseStudyState?.website_url}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                website_url: e.target.value,
              }));
            }}
            placeholder="Website URL"
          />

          {/* <DZTextValidation
            error={error?.image}
            label="Image"
            variant="outlined"
            value={caseStudyState?.image}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                image: e.target.value,
              }));
            }}
            placeholder="Image (file upload)"
            type="file" // Assuming you'll handle file uploads here
          /> */}

          {/* <DZTextValidation
            error={error?.client_img}
            label="Client Image"
            variant="outlined"
            value={caseStudyState?.client_img}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                client_img: e.target.value,
              }));
            }}
            placeholder="Client Image (file upload)"
            type="file" // Assuming you'll handle file uploads here
          /> */}

          <DZTextValidation
            error={error?.tools}
            label="Tools"
            variant="outlined"
            value={caseStudyState?.tools}
            onChange={(e) => {
              setCaseStudyState((x) => ({
                ...x,
                tools: e.target.value,
              }));
            }}
            placeholder="Tools"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedCaseStudy(null));
              }}
            >
              Cancel
            </Button>
            {selectedCaseStudy?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCaseStudyData("update");
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCaseStudyData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCaseStudy(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateCaseStudy;
